import React from 'react';
import Layout from '../components/layout';
import { Hero, Container } from 'react-bulma-components';
import Media from 'react-media';
import ReCAPTCHA from "react-google-recaptcha";
class Career extends React.Component {
    render(){
      const TEST_SITE_KEY = "6LfLWpkUAAAAACMj48TFIljr-gMM9d3cr0hcMDEm";
        return(
            <Layout title="Career" hover='career'>
            <Media query="(max-width:900px)">
                    {matches =>
                    matches ? (
                      <div className="mobileCareerForm">
                  <div className="thatCareerForm">
                  <p className="thatCareerLabel">To Join the LEBANON POULTRY family today, attach your CV below</p>
                  <form name="career" className="mobileForm" method="POST" data-netlify="true" action="/.netlify/functions/careerprime">
                 
                      <div className="field">
    
    
    <div className="control spef contactSec">
      <input class="input" type="email" placeholder="Major" />
    </div>
    
  </div>
  <div className="field">
  <div className="control spef contactSec">
      <input class="input" type="input" placeholder="Add the link to your CV (Google Drive or other)" />
    </div>
  </div>
  
  <div className="field">
    
    <div className="control spafC contactSec">
      <textarea class="textarea careerTextArea" placeholder="Message" rows="2"></textarea>
    </div>
  </div>
  <div className="field captcha">
  <div className="captchaMargin">
  <ReCAPTCHA
            onChange={this.handleChange}
            sitekey={TEST_SITE_KEY}
          />
  </div>
  
  </div>
  <div className="field is-grouped">
    <div class="control spif contactSec">
      <button class="button thatCareerButton is-link">Submit</button>
    </div>
  </div>
  </form>
                  </div>
              </div>
            ) : (
              <Hero className="careerHero" size="medium">
                      <Hero.Body className="thatCareerBody">
                          <Container fluid className="careerSec">
                          <div className="thatCareerForm">
                  <p className="thatCareerLabel">To Join the LEBANON POULTRY family today, attach your CV below</p>
                  <form name="career" method="POST" data-netlify="true" action="/.netlify/functions/careerprime">
                 <div className="field">
                 <div className="control spef">
      <input name="email" class="input" type="email" placeholder="Email" />
    </div>
                 </div>
                      <div className="field">
    
                      
    <div className="control spef">
      <input name="name" class="input" type="input" placeholder="Major" />
    </div>
    
  </div>
  <div className="field">
  <div className="control spef">
      <input name="cv" class="input" type="input" placeholder="Add the link to your CV (Google Drive or other)" />
    </div>
  </div>
  
  <div className="field">
    
    <div className="control spafC">
      <textarea name="message" class="textarea careerTextArea" placeholder="Message" rows="2"></textarea>
    </div>
  </div>
  <div className="field">
  <div className="control captcha">
  <ReCAPTCHA
            onChange={this.handleChange}
            sitekey={TEST_SITE_KEY}
          />
  </div>
  
  </div>
  
  
  <div className="field is-grouped">
    <div class="control spif">
      <button type="submit" class="button thatCareerButton is-link">Submit</button>
    </div>
  </div>
  </form>
                  </div>
                          </Container>
                  </Hero.Body>
                  </Hero>
              
            )
          }
        </Media>
        
                
            </Layout>
        );
    }
}
export default Career